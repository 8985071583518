import React, {useEffect} from "react";
import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css'

const {toggle} = createPopup('EL6JSOZS', {
    tracking: {
        "data-tf-hidden": {
            hubspot_utk: "linkedin",
            hubspot_page_name: "simple-cta",
            hubspot_page_url: "/simple-cta"
        }
    }
});

const SimpleCTA = () => {
    useEffect(() => {
        toggle();
    }, []);
    return (
        <div data-tf-popover="EL6JSOZS"></div>
    )
};

export default SimpleCTA;